import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";
import { Link } from "gatsby";

import AcademicStructure from "../components/academicStructure";
import AccredAndFinAid from "../components/accredAndFinAid";
import Celebrating30Years from "../components/celebrating30Years";
import DiscoverConcentrations from "../components/discoverConcentrations";
import DiscoverCTA from "../components/discoverCTA";
import DiscoverHeader from "../components/discover-header";
import DiscoverNavigation from "../components/discoverNavigation";
import GdprPanel from "../components/gdpr";
import {
  ImpactScholarshipBottomCTA,
  ImpactScholarshipTopCTA,
} from "../components/impactScholarshipCTA";
import LearningFormats from "../components/learningFormats";
import LPFooter from "../components/lp-footer";
import PageQuote from "../components/quote";
import Seo from "../components/seo";
import SubNavigation from "../components/subNav";

import "../styles/discover.scss";
import NewsletterSignup from "../components/newsletterSignUp";

export default function DiscoverPage({ data }) {
  const post = data.mdx;
  const shortcodes = {
    AcademicStructure,
    LearningFormats,
    AccredAndFinAid,
    PageQuote,
    DiscoverCTA,
    DiscoverConcentrations,
    Celebrating30Years,
    ImpactScholarshipBottomCTA,
    ImpactScholarshipTopCTA,
    Container,
    Row,
    Col,
    Link
  };

  return (
    <>
      <SSRProvider>
        <DiscoverNavigation />
        <DiscoverHeader
          menu={post.frontmatter.menuType}
          headline={post.frontmatter.headline}
          heroBackground={post.frontmatter.heroBackground}
          subHeadline={post.frontmatter.subheadline}
          header={post.frontmatter.headerType}
        />
        <SubNavigation type="new" subNav={post.frontmatter.subNav} />
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <LPFooter menuType={post.frontmatter.menuType} />
        <GdprPanel />
      </SSRProvider>
    </>
  );
}

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        heroBackground
        subheadline
        headline
        title
        headerType
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
        subNav {
          text
          url
        }
        menuType
      }
      body
    }
  }
`;
