import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { handleSubmit as formSubmitter } from "../../libs/form-submitter";
import Cookies from "js-cookie";

import "./styles/discoverContactForm.scss";
import "./styles/spinner.scss";

export default function DiscoverImpactForm({ submitCampaignID, submitType }) {
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  /* Get GAUID */
  const clientId = typeof document !== "undefined" ? Cookies.get("_ga") : null;
  const gaUID =
    clientId != null || clientId != undefined
      ? clientId.substring(6)
      : "No GAUID Detected";

  /* Get URL Params */
  const urlRef = typeof window !== "undefined" ? window.location.search : "";
  const url = urlRef.slice(1);
  const urlParams = new URLSearchParams(url);
  const utm_source = urlParams.has("utm_source")
    ? urlParams.get("utm_source")
    : "NoSource";
  const utm_medium = urlParams.has("utm_medium")
    ? urlParams.get("utm_medium")
    : "NoMedium";
  const utm_campaign = urlParams.has("utm_campaign")
    ? urlParams.get("utm_campaign")
    : "NoCampaign";
  const utm_term = urlParams.has("utm_term")
    ? urlParams.get("utm_term")
    : "NoTerm";
  const utm_content = urlParams.has("utm_content")
    ? urlParams.get("utm_content")
    : "NoContent";
  const utm_gclid = urlParams.has("gclid") ? urlParams.get("gclid") : "NoGCLID";

  /* Get Initial Cookie Value */
  var muSource = Cookies.get("_muSource");
  var muMedium = Cookies.get("_muMedium");
  var muCampaign = Cookies.get("_muCampaign");
  var muTerm = Cookies.get("_muTerm");
  var muContent = Cookies.get("_muContent");

  /* Set Cookie Value and Store for Logic App Values */
  var source;
  var medium;
  var campaign;
  var term;
  var content;
  source =
    muSource === undefined
      ? (Cookies.set("_muSource", utm_source, { expires: 7 }),
        (source = Cookies.get("_muSource")))
      : muSource === "NoSource" && utm_source !== "NoSource"
        ? (Cookies.set("_muSource", utm_source, { expires: 7 }),
          (source = Cookies.get("_muSource")))
        : muSource !== "NoSource" && utm_source !== "NoSource"
          ? (Cookies.set("_muSource", utm_source, { expires: 7 }),
            (source = Cookies.get("_muSource")))
          : (source = muSource);
  medium =
    muMedium === undefined
      ? (Cookies.set("_muMedium", utm_medium, { expires: 7 }),
        (medium = Cookies.get("_muMedium")))
      : muMedium === "NoMedium" && utm_medium !== "NoMedium"
        ? (Cookies.set("_muMedium", utm_medium, { expires: 7 }),
          (medium = Cookies.get("_muMedium")))
        : muMedium !== "NoMedium" && utm_medium !== "NoMedium"
          ? (Cookies.set("_muMedium", utm_medium, { expires: 7 }),
            (medium = Cookies.get("_muMedium")))
          : (medium = muMedium);
  campaign =
    muCampaign === undefined
      ? (Cookies.set("_muCampaign", utm_campaign, { expires: 7 }),
        (campaign = Cookies.get("_muCampaign")))
      : muCampaign === "NoCampaign" && utm_campaign !== "NoCampaign"
        ? (Cookies.set("_muCampaign", utm_campaign, { expires: 7 }),
          (campaign = Cookies.get("_muCampaign")))
        : muCampaign !== "NoCampaign" && utm_campaign !== "NoCampaign"
          ? (Cookies.set("_muCampaign", utm_campaign, { expires: 7 }),
            (campaign = Cookies.get("_muCampaign")))
          : (campaign = muCampaign);
  term =
    muTerm === undefined
      ? (Cookies.set("_muTerm", utm_term, { expires: 7 }),
        (term = Cookies.get("_muTerm")))
      : muTerm === "NoTerm" && utm_term !== "NoTerm"
        ? (Cookies.set("_muTerm", utm_term, { expires: 7 }),
          (term = Cookies.get("_muTerm")))
        : muTerm !== "NoTerm" && utm_term !== "NoTerm"
          ? (Cookies.set("_muTerm", utm_term, { expires: 7 }),
            (term = Cookies.get("_muTerm")))
          : (term = muTerm);
  content =
    muContent === undefined
      ? (Cookies.set("_muContent", utm_content, { expires: 7 }),
        (content = Cookies.get("_muContent")))
      : muContent === "NoContent" && utm_content !== "NoContent"
        ? (Cookies.set("_muContent", utm_content, { expires: 7 }),
          (content = Cookies.get("_muContent")))
        : muContent !== "NoContent" && utm_content !== "NoContent"
          ? (Cookies.set("_muContent", utm_content, { expires: 7 }),
            (content = Cookies.get("_muContent")))
          : (content = muContent);

  const degreeList = ["a1p3i0000009kh6", "a1p3i0000009khQ", "a1p3i0000009kha"];
  const formik = useFormik({
    initialValues: {
      submitFirstName: "",
      submitLastName: "",
      submitEmail: "",
      submitPhone: "",
      submitDegree: "",
      submitHighestLevelEducation: "",
      submitQuarter: "",
      submitHowHear: "",
      submitCampaignID,
      submitLearningFormat: "",
      submitType,
      submitUTMSource: source,
      submitUTMMedium: medium,
      submitUTMCampaign: campaign,
      submitUTMTerm: term,
      submitUTMContent: content,
      submitGCLID: utm_gclid,
      submitUUID: gaUID,
      submitForm: "Discover Impact Form",
    },

    validationSchema: Yup.object({
      submitFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      submitLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      submitEmail: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      submitPhone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Required"),
      submitDegree: Yup.string().required("Required"),
      submitHighestLevelEducation: Yup.string().required("Required"),
      submitQuarter: Yup.string().required("Required"),
      submitHowHear: Yup.string().required("Required"),
      submitLearningFormat: Yup.string()
        .when('submitDegree', {
          is:  (submitDegree) => degreeList.includes(submitDegree),  
          then: () => Yup.string().required("Required"),
          otherwise: () => Yup.string(),
        })
    }),
    onSubmit: () => formSubmitter(formValues, submitConfig),
  });

  const submitConfig = {
    preferThankYou: true,
    impactScholarship: "true",
  };

  const formValues = formik.values;

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div>
      <div id="submitting" className={isActive ? null : "show"}>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} className="p-3 p-lg-4 text-center">
            <h2 className="submitting-title">
              Submitting{" "}
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" />
            </h2>
          </Col>
        </Row>
      </div>
      <div id="discoverContactForm">
        <Row className="align-items-center justify-content-center">
          <Col className="contact-form text-center">
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="form-row">
                <Form.Group className="form-col">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="submitFirstName"
                    name="submitFirstName"
                    type="text"
                    placeholder="First Name*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitFirstName}
                    className={
                      formik.touched.submitFirstName &&
                      formik.errors.submitFirstName
                        ? `error`
                        : null
                    }
                  />
                  {formik.touched.submitFirstName &&
                  formik.errors.submitFirstName ? (
                    <div className="error-text">
                      {formik.errors.submitFirstName}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="form-col">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="submitLastName"
                    name="submitLastName"
                    type="text"
                    placeholder="Last Name*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitLastName}
                    className={
                      formik.touched.submitLastName &&
                      formik.errors.submitLastName
                        ? `error`
                        : null
                    }
                  />
                  {formik.touched.submitLastName &&
                  formik.errors.submitLastName ? (
                    <div className="error-text">
                      {formik.errors.submitLastName}
                    </div>
                  ) : null}
                </Form.Group>
              </Form.Group>
              <Form.Group className="form-row">
                <Form.Group className="form-col">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    id="submitEmail"
                    name="submitEmail"
                    type="email"
                    placeholder="Email Address*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={
                      formik.touched.submitEmail && formik.errors.submitEmail
                        ? `error`
                        : null
                    }
                  />
                  {formik.touched.submitEmail && formik.errors.submitEmail ? (
                    <div className="error-text">
                      {formik.errors.submitEmail}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="form-col">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    id="submitPhone"
                    name="submitPhone"
                    type="phone"
                    placeholder="Phone Number*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitPhone}
                    className={
                      formik.touched.submitPhone && formik.errors.submitPhone
                        ? `error`
                        : null
                    }
                  />
                  {formik.touched.submitPhone && formik.errors.submitPhone ? (
                    <div className="error-text">
                      {formik.errors.submitPhone}
                    </div>
                  ) : null}
                </Form.Group>
              </Form.Group>
              <Form.Group className="form-row">
                <Form.Group controlId="submitDegree" className="form-col w-100">
                  <Form.Select
                    aria-label="Degree Select List"
                    name="submitDegree"
                    id="submitDegree"
                    value={formik.values.submitDegree}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option key="blankChoice" hidden value>
                      Degree of Interest*
                    </option>
                    <option value="a1p3i0000009kha">
                      MBA in Creative Enterprise
                    </option>
                    <option value="a1p3i0000009khf">
                      MEd in Educational Leadership
                    </option>
                    <option value="a1p3i0000009khB">MA in Psychology</option>
                    <option value="a1p3i0000009kh6">
                      MA in Counseling Psychology
                    </option>
                    <option value="a1p3i0000009khk">
                      EdD in Organizational Leadership
                    </option>
                    <option value="a1p3i0000009khQ">
                      PsyD in Clinical Psychology
                    </option>
                    <option value="a1p3i0000009khG">PhD in Psychology</option>
                  </Form.Select>
                </Form.Group>
              </Form.Group>
              {(formik.values.submitDegree == "a1p3i0000009kh6" ||
                formik.values.submitDegree == "a1p3i0000009khQ" ||
                formik.values.submitDegree == "a1p3i0000009kha") && (
                <>
                  <Form.Group controlId="learningFormats" className="form-row">
                    <Form.Group
                      controlId="submitLearningFormats"
                      className="form-col w-100"
                    >
                      <Form.Select
                        aria-label="Learning Format"
                        id="submitLearningFormat"
                        name="submitLearningFormat"
                        value={formik.values.submitLearningFormat}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option key="blankChoice2" hidden value>
                          Learning Format*
                        </option>
                        <option value="online">Online</option>
                        <option value="hybridLA">
                          Hybrid - Los Angeles Campus
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Form.Group>
                </>
              )}
              <Form.Group className="form-row">
                <Form.Group className="form-col">
                  <Form.Select
                    aria-label="Highest Degree of Education"
                    id="submitHighestLevelEducation"
                    name="submitHighestLevelEducation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitHighestLevelEducation}
                  >
                    <option key="blankChoice" hidden value>
                      Highest Degree of Education*
                    </option>
                    <option value="Bachelor's Degree In Progress">
                      Bachelor's Degree In Progress
                    </option>
                    <option value="Bachelor's Degree">Bachelor's Degree</option>
                    <option value="Master's Degree in Progress">
                      Master's Degree in Progress
                    </option>
                    <option value="Master's Degree">Master's Degree</option>
                    <option value="Doctorate">Doctorate</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-col">
                  <Form.Select
                    aria-label="Anticipated Start Date"
                    id="submitQuarter"
                    name="submitQuarter"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitQuarter}
                  >
                    <option key="blankChoice" hidden value>
                      Anticipated Start Date
                    </option>
                    <option value="a2A3i0000004z3qEAA">Winter 2024-25</option>
                    <option value="a2A3i0000004z4TEAQ">Spring 2024-25</option>
                    <option value="a2A3i0000004z56EAA">Summer 2024-25</option>
                    <option value="a2A3i0000004z3EEAQ">Fall 2025-26</option>
                    <option value="a2A3i0000004z3rEAA">Winter 2025-26</option>
                  </Form.Select>
                </Form.Group>
              </Form.Group>
              <Form.Group controlId="startHear" className="form-row">
                <Form.Group
                  controlId="submitHowHear"
                  className="form-col w-100"
                >
                  <Form.Select
                    aria-label="Degree Select List"
                    name="submitHowHear"
                    id="submitHowHear"
                    value={formik.values.submitHowHear}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option key="blankChoice2" hidden value>
                      How Did You Hear About Meridian*
                    </option>
                    <option value="Google">Google</option>
                    <option value="Grad School Directory">
                      Grad School Directory
                    </option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Twitter">Twitter</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Faculty">Faculty</option>
                    <option value="Alumni">Alumni</option>
                    <option value="Current Student">Current Student</option>
                    <option value="Family/Friend">Family/Friend</option>
                    <option value="Meridian Partner">Meridian Partner</option>
                  </Form.Select>
                </Form.Group>
              </Form.Group>
              <Form.Group
                controlId="termsSubmit"
                className="form-row align-items-center"
              >
                <Form.Group controlId="legal" className="form-col">
                  <p className="legal">
                    By clicking this button, you are confirming that you agree
                    to our{" "}
                    <a href="/terms-and-conditions" target="_blank">
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a href="/privacy" target="_blank">
                      Privacy Policy
                    </a>
                    .
                  </p>
                </Form.Group>
                <Form.Group controlId="submit" className="form-col">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={ToggleClass}
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}
