import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import "./styles/footer.scss";

const LPFooter = ({ menuType }) => {
  if (menuType === "MBA") {
    return (
      <div id="lpFooter">
        <Container fluid="lg">
          <Row className="pt-lg-5 p-4 pb-lg-0 links justify-content-between text-center">
            <Col>
              <Link to="#mu-hero" className="footerNavLink">
                Learn More »
              </Link>
            </Col>
            <Col>
              <Link to="#purpose_at_work" className="footerNavLink">
                Purpose at Work
              </Link>
            </Col>
            <Col>
              <Link to="#professional_future" className="footerNavLink">
                Professional Future
              </Link>
            </Col>
            <Col>
              <Link to="#concentrations" className="footerNavLink">
                Concentrations
              </Link>
            </Col>
            <Col>
              <Link to="#academic_structure" className="footerNavLink">
                Academic Structure
              </Link>
            </Col>
            <Col>
              <Link to="#learning_format" className="footerNavLink">
                Learning Formats
              </Link>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container fluid="lg">
          <Row className="pt-lg-0 p-4 justify-content-between">
            <Col lg={5} xs={12}>
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/discover-meridian-university-footer-logo.svg"
                alt="Meridian University Footer Logo"
                className="img-fluid"
              />
            </Col>
            <Col lg={4} xs={12} className="text-end">
              <p className="copyright align-self-end">
                Copyright © 1993-{new Date().getUTCFullYear()}, Meridian
                University
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (menuType === "PSYCH") {
    return (
      <div id="lpFooter">
        <Container fluid="lg">
          <Row className="pt-lg-5 p-4 pb-0 links justify-content-between text-center">
            <Col xs={6} md={4} lg className="mb-md-4 mb-lg-0 pb-md-2 pb-lg-0">
              <Link to="#mu-hero" className="footerNavLink">
                Learn More »
              </Link>
            </Col>
            <Col
              xs={6}
              md={4}
              lg={3}
              className="mb-md-4 mb-lg-0 pb-md-2 pb-lg-0"
            >
              <Link
                to="#reimagining_professional_practice"
                className="footerNavLink"
              >
                Reimagining Professional Practice
              </Link>
            </Col>
            <Col xs={6} md={4} lg className="mb-md-4 mb-lg-0 pb-md-2 pb-lg-0">
              <Link to="#professional_future" className="footerNavLink">
                Professional Future
              </Link>
            </Col>
            <Col xs={6} md={4} lg>
              <Link to="#concentrations" className="footerNavLink">
                Concentrations
              </Link>
            </Col>
            <Col xs={6} md={4} lg>
              <Link to="#academic_structure" className="footerNavLink">
                Academic Structure
              </Link>
            </Col>
            <Col xs={6} md={4} lg>
              <Link to="#learning_format" className="footerNavLink">
                Learning Formats
              </Link>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container fluid="lg">
          <Row className="pt-0 p-4 justify-content-lg-between justify-content-around align-items-center">
            <Col md={5} xs={12}>
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/discover-meridian-university-footer-logo.svg"
                alt="Meridian University Footer Logo"
                className="img-fluid"
              />
            </Col>
            <Col md={5} xs={12} className="text-end">
              <p className="copyright">
                Copyright © 1993-{new Date().getUTCFullYear()}, Meridian
                University
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (menuType === "EDU") {
    return (
      <div id="lpFooter">
        <Container fluid="lg">
          <Row className="pt-lg-5 p-4 pb-lg-0 links justify-content-between text-center">
            <Col>
              <Link to="#mu-hero" className="footerNavLink">
                Learn More »
              </Link>
            </Col>
            <Col>
              <Link to="#developing_learning_leaders" className="footerNavLink">
                Developing Learning Leaders
              </Link>
            </Col>
            <Col>
              <Link to="#professional_future" className="footerNavLink">
                Professional Future
              </Link>
            </Col>
            <Col>
              <Link to="#concentrations" className="footerNavLink">
                Concentrations
              </Link>
            </Col>
            <Col>
              <Link to="#academic_structure" className="footerNavLink">
                Academic Structure
              </Link>
            </Col>
            <Col>
              <Link to="#learning_format" className="footerNavLink">
                Learning Formats
              </Link>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container fluid="lg">
          <Row className="pt-lg-0 p-4 justify-content-between">
            <Col lg={5} xs={12}>
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/discover-meridian-university-footer-logo.svg"
                alt="Meridian University Footer Logo"
                className="img-fluid"
              />
            </Col>
            <Col lg={4} xs={12} className="text-end">
              <p className="copyright align-self-end">
                Copyright © 1993-{new Date().getUTCFullYear()}, Meridian
                University
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (menuType === "ALL") {
    return (
      <div id="lpFooter">
        <Container fluid="lg">
          <Row className="pt-lg-5 p-4 pb-lg-0 links justify-content-between text-center">
            <Col>
              <Link to="#mu-hero" className="footerNavLink">
                Learn More »
              </Link>
            </Col>
            <Col>
              <Link
                to="#transformative_graduate_education"
                className="footerNavLink"
              >
                Transformative Graduate Education
              </Link>
            </Col>
            <Col>
              <Link to="#professional_future" className="footerNavLink">
                Professional Future
              </Link>
            </Col>
            <Col>
              <Link to="#concentrations" className="footerNavLink">
                Concentrations
              </Link>
            </Col>
            <Col>
              <Link to="#academic_structure" className="footerNavLink">
                Academic Structure
              </Link>
            </Col>
            <Col>
              <Link to="#learning_format" className="footerNavLink">
                Learning Formats
              </Link>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container fluid="lg">
          <Row className="pt-lg-0 p-4 justify-content-between">
            <Col lg={5} xs={12}>
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/discover-meridian-university-footer-logo.svg"
                alt="Meridian University Footer Logo"
                className="img-fluid"
              />
            </Col>
            <Col lg={4} xs={12} className="text-end">
              <p className="copyright align-self-end">
                Copyright © 1993-{new Date().getUTCFullYear()}, Meridian
                University
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (menuType === "hybrid") {
    return (
      <div id="lpFooter">
        <Container fluid="lg">
          <Row className="pt-lg-5 p-4 pb-lg-0 links justify-content-between text-center">
            <Col>
              <Link to="#mu-hero" className="footerNavLink">
                Learn More »
              </Link>
            </Col>
            <Col>
              <Link
                to="#transformative_education"
                className="footerNavLink"
              >
                Transformative Education
              </Link>
            </Col>
            <Col>
              <Link to="#hybrid_programs" className="footerNavLink">
                Hybrid Programs
              </Link>
            </Col>
            <Col>
              <Link to="#admissions_financial_aid" className="footerNavLink">
                Admissions & Financial Aid
              </Link>
            </Col>
            <Col>
              <Link to="#academic_structure" className="footerNavLink">
                Academic Structure
              </Link>
            </Col>
            <Col>
              <Link to="#los_angeles_campus" className="footerNavLink">
                Los Angeles Campus
              </Link>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container fluid="lg">
          <Row className="pt-lg-0 p-4 justify-content-between">
            <Col lg={5} xs={12}>
              <img
                src="https://web-cdn.meridianuniversity.edu/site-content-images/discover/discover-meridian-university-footer-logo.svg"
                alt="Meridian University Footer Logo"
                className="img-fluid"
              />
            </Col>
            <Col lg={4} xs={12} className="text-end">
              <p className="copyright align-self-end">
                Copyright © 1993-{new Date().getUTCFullYear()}, Meridian
                University
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

export default LPFooter;
